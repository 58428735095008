import React from 'react';
import TextField from '../../Common/TextField/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(()=>({
  container:{
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    rowGap: "16px"
  },
  textField: {
    width: "auto",
    "& label": {
      transform: "translate(14px, -6px) scale(0.75) !important",
      transformOrigin: "top left !important"},
    "& legend": {
      maxWidth: "1000px",
    }
  },
  price: {
    marginBottom: '16px',
    width: '100%'
  }
}));

 const DiscountDatePicker:React.FC = () => {
  const classes = useStyles();

  return <form className={classes.container} noValidate>
  <TextField
    id="datetime-local-start"
    name="datetime-local-start"
    label="Data rozpoczęcia"
    path={"price.startDiscountDate"}
    type="datetime-local"
    className={classes.textField}
  />
  <TextField
    id="datetime-local-end"
    name="datetime-local-end"
    label="Data zakończenia"
    path={"price.endDiscountDate"}
    type="datetime-local"
    className={classes.textField}
  />
</form>
}

export const DiscountBox = () => {
  const classes = useStyles();

  return <Box>
    <TextField
            label={"Cena promocyjna"}
            path={"price.discountedValue"}
            type={"number"}
            className={classes.price}
        />
    <DiscountDatePicker />
  </Box>
}