import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import axios from "axios";
import {BackendUser, getAxiosConfig, NotLoggedInAction, SetUserAction, UserData} from "./User";
import {parseError} from "./Error";
import {searchProducts} from "./Products";
import {AddNotificationAction} from "./Notifications";

export const changeActiveProductsByBrand = (id: string | undefined, active: boolean): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        if (!id) {
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/change-active-products-by-brand/${id}`, {active}, getAxiosConfig());
            dispatch(searchProducts());
        } catch (e) {
            parseError(e);
        }
    };

export const removeProductsByBrand = (id: string | undefined): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        if (!id) {
            return;
        }
        try {
            await axios.delete(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/products-remove-by-brand/${id}`, getAxiosConfig());
            dispatch(searchProducts());
        } catch (e) {
            parseError(e);
        }
    };

export const removeProductsByCategory = (id: string | undefined): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        if (!id) {
            return;
        }
        try {
            await axios.delete(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/products-remove-by-category/${id}`, getAxiosConfig());
            dispatch(searchProducts());
        } catch (e) {
            parseError(e);
        }
    };


export const changeActiveProductsByCategory = (id: string | undefined, active: boolean): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        if (!id) {
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/change-active-products-by-category/${id}`, {active}, getAxiosConfig());
            dispatch(searchProducts());
        } catch (e) {
            parseError(e);
        }
    };

export const getUsers = (): ThunkAction<Promise<BackendUser[]>, AppState, {}, SetUserAction | NotLoggedInAction | AddNotificationAction> =>
    async () => {
        try {
            const data = (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/users-all`, getAxiosConfig())).data;
            return data;
        } catch (e) {
            parseError(e);
        }
    };

export const addUser = (userData: UserData): ThunkAction<Promise<string | void>, AppState, {}, SetUserAction | NotLoggedInAction | AddNotificationAction> =>
    async () => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/user-add`, {...userData}, getAxiosConfig());
        } catch (e) {
            parseError(e);
        }
    };

export const editUser = (id: string, userData: UserData): ThunkAction<Promise<string | void>, AppState, {}, SetUserAction | NotLoggedInAction | AddNotificationAction> =>
    async () => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/user-edit/${id}`, {...userData}, getAxiosConfig());
        } catch (e) {
            parseError(e);
        }
    };

export const deleteUser = (id: string): ThunkAction<Promise<string | void>, AppState, {}, SetUserAction | NotLoggedInAction | AddNotificationAction> =>
    async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_DATA_ENDPOINT}/admin/user-delete/${id}`, getAxiosConfig());
        } catch (e) {
            parseError(e);
        }
    };
