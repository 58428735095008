import React, { useState } from "react";
import Box from "../../../ProductView/Common/Box/Box";
import Common from "../Common/Common";
import {SyncOutlined} from '@material-ui/icons';
import SyncDialog from "../SyncDialog/SyncDialog";

const SyncPrices: React.FC = () => {
    const [brandId, setBrandId] = useState<string|null>(null);
    const [categoryId, setCategoryId] = useState<string|null>(null);

    const runWithCategory = (categoryId: string) => {
        setCategoryId(categoryId);
    }

    const runWithBrand = (brandId: string) => {
        setBrandId(brandId);
    }

    return <Box title={"Synchronizuj ceny"}>
        <Common
            texts={{
                byCategory: "Czy na pewno chcesz synchronizować wszystkie ceny produktów z tej kategorii?",
                byBrand: "Czy na pewno chcesz synchronizować wszystkie ceny produktów z tej marki?"
            }}
            icon={<SyncOutlined/>}
            color={"primary"}
            actions={{
                byCategory: async (id?: string) => runWithCategory(id!!),
                byBrand: async (id?: string) => runWithBrand(id!!),
            }}
        />
        { brandId && <SyncDialog
            type={"brand"}
            actionPath={"/massUpdatePrices"}
            actionId={brandId}
            open={brandId != null}
            onComplete={async () => {
                setBrandId(null);
            }}
        />}
        { categoryId && <SyncDialog
            type={"category"}
            actionPath={"/massUpdatePrices"}
            actionId={categoryId}
            open={categoryId != null}
            onComplete={async () => {
                setCategoryId(null);
            }}
        />}
    </Box>
}

export default SyncPrices;
