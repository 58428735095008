import React, {Reducer, useEffect, useReducer, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import styles from "./SyncDialog.module.scss";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { io } from 'socket.io-client';
import { LocalStorageKey } from "../../../../Utils/StorageUtils";

interface IProps {
    onComplete: () => Promise<void>,
    open: boolean,
    className?: string,
    type: string,
    actionId: string,
    actionPath: string,
}

interface IState {
    actionId?: number,
    progress: number,
    total: number,
    done: boolean,
    errorMessage?: string
}

const initialState: IState = {
    progress: 0,
    total: 0,
    done: false
};

interface IAction {
    type: string;
    value?: string;
}

const reducer = (state: IState, action: IAction) => {
    switch (action.type) {
        case "reset": return initialState;
    }
    return state;
};

const SyncDialog: React.FC<IProps> = (props) => {
    const isApi = process.env.REACT_APP_DATA_ENDPOINT?.endsWith("/api");
    const baseUri = process.env.REACT_APP_DATA_ENDPOINT?.replace("/api", "");
    const socket = io(`${baseUri}${props.actionPath}`, {
        path: isApi ? "/api/socket.io" : "/socket.io",
        auth: {
            token: localStorage.getItem(LocalStorageKey.TOKEN)
        },
        autoConnect: false
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_state, dispatch] = useReducer<Reducer<IState, IAction>, IState>(reducer, initialState, () => initialState);
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0);
    const [done, setDone] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        function onConnect() {
          setIsConnected(true);
        }

        function onDisconnect() {
          setIsConnected(false);
        }

        function onStart() {
        }

        function onCount(data: {count: number}) {
            setTotal(data.count);

        }
        function onProgress(data: {progress: number}) {
            setProgress(data.progress);
        }

        function onDone() {
            setDone(true);
            setIsConnected(false);
            socket.disconnect();
        }

        function onError(data: {message: string}) {
            socket.disconnect();
            socket.close();
            setErrorMessage(data.message);
            setDone(true);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('start', onStart);
        socket.on('count', onCount);
        socket.on('progress', onProgress);
        socket.on('done', onDone);
        socket.on('error', onError);

        socket.connect();
        socket.emit(props.type, {id: props.actionId});

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('start', onStart);
            socket.off('count', onCount);
            socket.off('progress', onProgress);
            socket.off('done', onDone);
            socket.off('error', onError);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle>{total === 0 ? "Przetwarzam..." : done ? `Przetworzono ${total}` : `Przetwarzam (${progress}/${total})`}</DialogTitle>
        <DialogContent className={styles.body}>
            {isConnected && <LinearProgress variant={total > 0 ? "determinate" : "indeterminate"} value={(progress/total)*100} />}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </DialogContent>
        <DialogActions className={styles.actions}>
            <Button
                disabled={!done}
                color="primary"
                variant={"contained"}
                onClick={() => {
                    dispatch({ type: "reset" });
                    props.onComplete();
                }}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
}

export default SyncDialog;
