import React from "react";
import * as Styled from "./MassChanges.styled";
import DeactivateProducts from "./DeactivateProducts/DeactivateProducts";
import RemoveProducts from "./RemoveProducts/RemoveProducts";
import ActivateProducts from "./ActivateProducts/ActivateProducts";
import SyncPrices from "./SyncPrices/SyncPrices";
import { Grid } from "@material-ui/core";
import SyncStatus from "./SyncStatus/SyncStatus";

interface Props {
    hidden?: boolean
}

const MassChanges: React.FC<Props> = ({hidden}) => {
    return <Styled.Content hidden={hidden}>
        <Grid container spacing={2}>
            <Grid item xs={6}><ActivateProducts/></Grid>
            <Grid item xs={6}><DeactivateProducts/></Grid>
            <Grid item xs={6}><RemoveProducts/></Grid>
            <Grid item xs={6}><SyncPrices/></Grid>
            <Grid item xs={6}><SyncStatus/></Grid>
        </Grid>
    </Styled.Content>
}

export default MassChanges;
