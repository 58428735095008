import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import styles from "./Photos.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../Store";
import {Photo, ProductTemplate, TemplateGuideLine} from "mesmetric-v2-common/models";
import {getCroppedSrc} from "../../../../Utils/PhotoUtils";
import Square from "../../ProductDetails/FilesUploaded/Square/Square";
import Box from "../../Common/Box/Box";
import {GUIDELINES_PATH, updateValue} from "../../../../ActionCreators/ProductData";
import PhotoCrop from "../../ProductDetails/FilesUploaded/Photos/PhotoCrop/PhotoCrop";
import {DeleteButton, DownloadButton, FreeButton, SquareButton} from "./Buttons";
import ProductPhotos from "./ProductPhotos";
import {CheckboxButtonNotConnected} from "../../Common/CheckboxButton/CheckboxButton";
import Gallery from "./Gallery";

const SHOP_PHOTOS = "shop-photos";

interface PhotosState {
    croppedPhoto?: Photo,
    photoCropModalOpen: boolean,
    aspectName: "1:1" | "free"
}

export const SHOP_PHOTOS_PATH = "shop.photos";

const Photos: React.FC = () => {
    const shopPhotos = useSelector<AppState, Photo[]>(state => state.ProductData.productData?.shop?.photos || [])
    const productPhotos = useSelector<AppState, Photo[]>(state => state.ProductData.productData?.photos || []);
    const [editedPhoto, setEditedPhoto] = useState<PhotosState>({
        photoCropModalOpen: false,
        aspectName: "free",
        croppedPhoto: undefined
    });
    const guidelines = useSelector<AppState, TemplateGuideLine[]>(state => (state.ProductData.productData?.primaryCategory.template as ProductTemplate)?.guidelines)
    const dispatch = useDispatch();
    const [croppedThumbnails, setCroppedThumbnails] = useState(true);
    const updatePhotos = (photos: Photo[]) => {
        dispatch(updateValue(SHOP_PHOTOS_PATH, photos));
    }

    const onDragEnd = (result: DropResult): void => {
        if (result.source.droppableId !== result.destination?.droppableId) {
            if (result.destination?.droppableId === SHOP_PHOTOS) {
                const foundPhoto = productPhotos?.find(photo => photo._id === result.draggableId)
                if (foundPhoto) {
                    shopPhotos.splice(result.destination.index, 0, foundPhoto);
                    updatePhotos(shopPhotos);
                }
            } else {
                if (productPhotos.some(photo => photo._id === result.draggableId)) {
                    updatePhotos(shopPhotos.filter(photo => photo._id !== result.draggableId))
                }
            }
        } else if (result.source.droppableId === SHOP_PHOTOS) {
            const photo = shopPhotos[result.source.index];
            shopPhotos.splice(result.source.index, 1);
            shopPhotos.splice(result.destination.index, 0, photo);
            updatePhotos(shopPhotos);
        }
    };

    return <>
        {editedPhoto.photoCropModalOpen &&
        editedPhoto.croppedPhoto &&
        <PhotoCrop
            photo={editedPhoto.croppedPhoto}
            aspectName={editedPhoto.aspectName}
            guidelines={guidelines}
            onChange={photo => {
                const index = shopPhotos.findIndex(p => p._id === photo._id);
                const photos = [...shopPhotos.filter(p => p._id !== photo._id)];
                setEditedPhoto({
                    croppedPhoto: undefined,
                    photoCropModalOpen: false,
                    aspectName: "free"
                });
                photos.splice(index, 0, photo);
                dispatch(updateValue(SHOP_PHOTOS_PATH, photos));
                dispatch(updateValue(GUIDELINES_PATH, guidelines));
            }}
            onCancel={() => setEditedPhoto({croppedPhoto: undefined, photoCropModalOpen: false, aspectName: "free"})}
        />}
        <div className={styles.photos}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Box title={"Zdjęcia sklep"} className={styles.shopPhotos}>
                    <div className={styles.controls}>
                        <CheckboxButtonNotConnected
                            label={"Przycięte miniatury"}
                            value={croppedThumbnails}
                            onChange={value => setCroppedThumbnails(value)}
                        />
                        <Gallery path={"shop.photos"} croppedThumbnails={croppedThumbnails}/>
                    </div>
                    <Droppable droppableId={SHOP_PHOTOS}>
                        {(provided) =>
                            <div ref={provided.innerRef} className={styles.list}>
                                {shopPhotos?.map((photo, index) =>
                                    <Draggable key={photo._id} draggableId={photo._id} index={index}>
                                        {provided =>
                                            <div
                                                className={styles.photoDraggableWrapper}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Square
                                                    className={styles.photo}
                                                    style={{backgroundImage: `url(${photo.src && croppedThumbnails ? getCroppedSrc(photo, {w: 132}) : photo.srcResolved})`}}>
                                                </Square>
                                                {!productPhotos.some(pp => pp._id === photo._id) &&
                                                <div className={styles.buttons}>
                                                    <SquareButton
                                                        photo={photo}
                                                        onClick={() => {
                                                            setEditedPhoto({
                                                                croppedPhoto: photo,
                                                                photoCropModalOpen: true,
                                                                aspectName: '1:1'
                                                            })
                                                        }}/>
                                                    <FreeButton
                                                        photo={photo}
                                                        onClick={() => {
                                                            setEditedPhoto({
                                                                croppedPhoto: photo,
                                                                photoCropModalOpen: true,
                                                                aspectName: 'free'
                                                            });
                                                        }}/>
                                                    <DownloadButton photo={photo}/>
                                                    <DeleteButton
                                                        photos={shopPhotos}
                                                        photo={photo}
                                                        update={photos => dispatch(updateValue(SHOP_PHOTOS_PATH, photos))}/>
                                                </div>
                                                }
                                            </div>
                                        }
                                    </Draggable>
                                )}
                            </div>}
                    </Droppable>
                </Box>
                <ProductPhotos
                    shopPhotos={shopPhotos}
                    productPhotos={productPhotos}/>
            </DragDropContext>
        </div>
    </>;
};


export default Photos;
