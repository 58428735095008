import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { Price } from "mesmetric-v2-common/models";
import { getAxiosConfig } from "../../../ActionCreators/User";
import axios from "axios";
import { parseError } from "../../../ActionCreators/Error";
import Spinner from "../Common/Spinner/Spinner";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

interface Props {
    visible?: boolean
}

interface User {
    login: string,
}

interface Model {
    createdAt?: Date,
    createdBy?: User,
    updatedAt?: Date,
    updatedBy?: User,
}

type PriceModel = Price & Model;

const PriceHistory: React.FC<Props> = ({visible}) => {
    const productId = useSelector<AppState, string | undefined>(state => state.ProductData.productData?._id);

    const getPriceHistory = async (): Promise<PriceModel[]> => {
        try {
            const response = await axios.get<PriceModel[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${productId}/priceHistory`, getAxiosConfig());
            return response.data;
        } catch (e) {
            parseError(e);
            throw new Error();
        }
    };

    const [loaded, setLoaded] = useState<boolean>(false);
    const [priceHistory, setPriceHistory] = useState<PriceModel[]>([]);

    useEffect(() => {
        (async () => {
            if (visible) {
                setPriceHistory(await getPriceHistory());
                setLoaded(true);
            }
        })()

        return () => {
            setPriceHistory([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const renderProduct = (): JSX.Element | null => {
        return <>
            <TableContainer>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell>Kiedy</TableCell>
                    <TableCell>Typ</TableCell>
                    <TableCell>Waluta</TableCell>
                    <TableCell align="right">Wartość</TableCell>
                    <TableCell align="right">Obliczona</TableCell>
                    <TableCell align="right">Kto</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {priceHistory.map((price) => (
                    <TableRow key={price.calculated}>
                    <TableCell>{price.updatedAt}</TableCell>
                    <TableCell component="th" scope="row">{price.type.name}</TableCell>
                    <TableCell>{price.currency.code}</TableCell>
                    <TableCell align="right">{price.value}</TableCell>
                    <TableCell align="right">{price.calculated}</TableCell>
                    <TableCell align="right">{price?.updatedBy?.login}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </>;
    }

    return <div hidden={!visible}>
        {!loaded && <Spinner size={64}/>}
        {loaded && renderProduct()}
    </div>;
};

export default PriceHistory;
