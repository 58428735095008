import React from "react";
import {Photo} from "mesmetric-v2-common/models";
import Button from "@material-ui/core/Button";
import styles from "./Photos.module.scss";
import CropDinIcon from "@material-ui/icons/CropDin";
import CropIcon from "@material-ui/icons/Crop";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import DragAndDropArray from "../../../../Helpers/DragAndDropArray";
import DeleteIcon from "@material-ui/icons/Delete";

export const SquareButton: React.FC<{ photo: Photo, onClick: () => void, className?: string }> = ({ photo, onClick, className }) => {
    const active = photo.crops.filter(crop => crop.aspect === "1:1")[0]?.active || false;

    return <Button
        disabled={!photo.src}
        size={"small"}
        variant={"contained"}
        className={`${styles.buttonWithIcon} ${active ? styles.activeCrop : ""} ${className}`}
        title={"1:1"}
        onClick={() => {
            if (!photo.src) {
                return;
            }
            onClick();
        }}
    >
        <CropDinIcon fontSize={"small"}/>
    </Button>;
}

export const FreeButton: React.FC<{ photo: Photo, onClick: () => void }> = ({photo, onClick}) => {
    const active = photo.crops.filter(crop => crop.aspect === "free")[0]?.active || false;

    return <Button
        disabled={!photo.src}
        size={"small"}
        variant={"contained"}
        className={`${styles.buttonWithIcon} ${active ? styles.activeCrop : ""}`}
        title={"Dowolny"}
        onClick={() => {
            if (!photo.src) {
                return;
            }
            onClick();
        }}
    >
        <CropIcon fontSize={"small"}/>
    </Button>;
}

export const DownloadButton: React.FC<{ photo: Photo }> = ({photo}) => {
    return <Button
        className={styles.buttonWithIcon}
        size={"small"}
        href={photo.srcResolved || ""}
        target={"_blank"}
        title={"Pobierz"}
        variant={"contained"}>
        <CloudDownloadOutlined/>
    </Button>
}

export const DeleteButton: React.FC<{ photos: Photo[], photo: Photo, update: (photos: Photo[]) => void }> = (
    {
        photo,
        photos,
        update
    }) => {
    return <Button
        size={"small"}
        variant={"contained"}
        color={"secondary"}
        title={"Usuń"}
        className={styles.buttonWithIcon}
        onClick={() => {
            DragAndDropArray.removeItem(photos, photo, update);
        }}
    ><DeleteIcon/>
    </Button>
}
